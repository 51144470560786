<!--
 * @Author: wxb
 * @Date: 2021-07-06 14:03:44
 * @LastEditTime: 2023-03-21 12:00:15
 * @LastEditors: wangxb 18254141586@163.com
 * @Description:
 * @FilePath: \ui-djz\src\views\Personal\userInfo.vue
-->
<template>
  <div class="containner">
    <div class="userinfo">
      <div class="information-avatarImg">
        <el-upload class="avatar-uploader"
                   action="/api/company/file/upload"
                   :on-change="handleAvatarChange"
                   :show-file-list="false"
                   :http-request="handleUpload">
          <img :src="avatarImg"
               class="avatar">
        </el-upload>
      </div>
      <div class="information-nickname-item">
        <div class="information-nickname-item-content">
          <div class="show-info"
               v-show="!nameEditDialog">
            <strong class="flex-value">{{userInfo.name}}</strong>
            <div class="edit-btn"
                 @click="nameEditDialog = true">
              <i class="el-icon-edit"></i>
            </div>
          </div>
          <div class="edit-info"
               v-show="nameEditDialog">
            <el-form :model="updateNameForm"
                     :rules="rules4UpdateName"
                     @submit.native.prevent
                     ref="updateNameForm"
                     :inline="true">
              <el-form-item prop="name">
                <el-input v-model="updateNameForm.name"
                          placeholder="请输入昵称"></el-input>
              </el-form-item>
              <el-form-item class="edit-info-btn">
                <el-button type="primary"
                           size="small"
                           @click="updateName()">确定</el-button>
                <el-button type="warning"
                           size="small"
                           @click="nameEditDialog = false">取消</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="member">
      <div class="member-title">会员信息</div>
      <div class="member-list">
        <el-table :data="tableData"
                  style="width: 100%"
                  header-row-class-name="bg-f3">
          <template slot="empty">
            <el-empty description="哎呀，未查询到会员开通记录"></el-empty>
          </template>
          <el-table-column prop="openingMode"
                           label="开通类型">
            <template slot-scope="scope">
              <span v-if="scope.row.openingMode==='1'">开通会员</span>
              <span v-else-if="scope.row.openingMode==='2'">续费会员</span>
            </template>
          </el-table-column>
          <el-table-column prop="packageTerm"
                           label="开通期限">
            <template slot-scope="scope">
              <span v-if="scope.row.daysUnit==='1'">{{scope.row.packageTerm}}天</span>
              <span v-else-if="scope.row.daysUnit==='2'">{{scope.row.packageTerm}}月</span>
              <span v-else-if="scope.row.daysUnit==='3'">{{scope.row.packageTerm}}季</span>
              <span v-else-if="scope.row.daysUnit==='4'">{{scope.row.packageTerm}}年</span>
              <span v-else-if="scope.row.daysUnit==='5'">{{scope.row.packageTerm}}周</span>
            </template>
          </el-table-column>
          <el-table-column prop="takeEffectTime"
                           label="生效时间">
          </el-table-column>
          <el-table-column prop="invalidTime"
                           label="失效时间">
          </el-table-column>
          <el-table-column prop="paymentAmount"
                           label="支付金额（￥）">
          </el-table-column>
        </el-table>
        <div class="content-page">
          <el-pagination background
                         layout="prev, slot, next"
                         :total="total"
                         :current-page.sync="currentPage"
                         @current-change="handleCurrentChange"
                         :hide-on-single-page="true">
            <span class="current-page">{{currentPage}}</span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currentUserInfo, getAvatar, saveOrUpdate, getMembershipRecords } from '@/api/user'
import { getToken } from '@/utils/auth'
export default {
  name: 'userInfo',
  data() {
    return {
      avatarImg: require('../../assets/avatar.png'),
      userInfo: {},
      nameEditDialog: false,
      updateNameForm: {
        name: ''
      },
      rules4UpdateName: {
        name: [
          { required: true, message: '请输入昵称', trigger: ['blur', 'change'] },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: ['blur', 'change'] }
        ]
      },
      avatarFile: null,
      tableData: [],
      searchForm: {
        start: 0,
        limit: 10
      },
      total: 0,
      currentPage: 1
    }
  },
  methods: {
    // 修改头像
    handleAvatarChange(file, fileList) {
      this.avatarFile = file.raw
      this.avatarImg = URL.createObjectURL(file.raw)
    },
    // 上传头像
    handleUpload() {
      const formData = new FormData()
      formData.append('id', this.userInfo.id)
      formData.append('username', this.userInfo.username)
      formData.append('departId', this.userInfo.departId)
      formData.append('avatar', this.avatarFile)
      saveOrUpdate(formData)
        .then(data => {
          if (data.status === 200) {
            this.$emit('handleAvatarUpload')
          }
        })
    },
    // 修改昵称
    updateName() {
      this.$refs.updateNameForm.validate(valid => {
        if (valid) {
          const formData = new FormData()
          formData.append('id', this.userInfo.id)
          formData.append('name', this.updateNameForm.name)
          formData.append('username', this.userInfo.username)
          formData.append('departId', this.userInfo.departId)
          saveOrUpdate(formData)
            .then(data => {
              if (data.status === 200) {
                this.nameEditDialog = false
                this.updateNameForm.name = ''
                this.userInfo.name = data.data.name
                this.$emit('handleAvatarUpload')
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getMembershipRecords() {
      getMembershipRecords(this.searchForm).then(res => {
        if (res.ok) {
          this.tableData = res.lst
          this.total = res.cnt
        }
      })
    },
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getMembershipRecords()
    }
  },
  mounted() {
    if (getToken()) {
      getAvatar().then(res => {
        if (res.size !== 0) {
          // 构造一个blob对象来处理数据
          const blob = new Blob([res])
          this.avatarImg = URL.createObjectURL(blob)
        } else {
          if (!this.headImgUrl) {
            this.$store.dispatch('getWechatInfo').then(res => {
              if (res.wechatUserInfo) {
                this.avatarImg = res.wechatUserInfo.headImgUrl
              }
            })
          } else {
            this.avatarImg = this.headImgUrl
          }
        }
      })

      currentUserInfo().then(res => {
        this.userInfo = res.data
      }).catch(error => {
        console.error(error)
      })

      this.getMembershipRecords()
    }
  }
}
</script>

<style lang="scss" scoped>
.containner {
  width: 100%;
  padding-left: 2rem;
}

.userinfo {
  background: url("../../assets/Personal/bg-userinfo.png");
  height: 28.4rem;
  text-align: center;
}

.information {
  &-avatarImg {
    padding: 20px 0px;
  }

  &-nickname-item {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center;
    height: 2.4rem;
    color: #ffffff;
    line-height: 2.4rem;
    &-content {
      position: relative;
      text-align: center;
      .flex-value {
        font-size: 2.4rem;
        font-weight: 500;
      }

      .edit-btn {
        display: inline-block;
        margin-left: 6px;
        cursor: pointer;
        font-size: 1.4rem;
        &:hover {
          color: #398ce3;
        }
      }

      .edit-info {
        .el-form {
          width: 450px;
          margin: 0 auto;
        }
        &-btn {
          text-align: center;
        }
      }
    }
  }
}

.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
  }
}

.avatar {
  width: 14.2rem;
  height: 14.2rem;
  display: block;
  border-radius: 50%;
}

.member {
  margin-top: 3rem;
  &-title {
    font-size: 1.8rem;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
  }

  &-list {
    margin-top: 2rem;

    /deep/ .bg-f3 {
      background: #f3f3f3;
      th,
      tr {
        background: #f3f3f3 !important;
      }

      .cell {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.65);
        line-height: 1.6rem;
        font-weight: 400;
      }
    }
    .content-page {
      margin-top: 10px;
      text-align: right;
    }
  }
}
</style>
